import { httpClient, httpClientEnrollment } from "../common/httpClient";
import API_CONST from '@/constants/ApiConst';
import { ContactDataCvsParams } from '@/Model/shared/types';


export function guardianFamilies(body: any) {
  let url_ = API_CONST.GET_GUARDIAN_FAMILIES_INFO;
  if (body.accountId === null)
      throw new Error("The parameter 'accountId' cannot be null.");
  else if (body.accountId !== undefined)
      url_ += "?accountId=" + encodeURIComponent("" + body.accountId) + "&";
  if (body.programId === null)
      throw new Error("The parameter 'programId' cannot be null.");
  else if (body.programId !== undefined)
      url_ += "programId=" + encodeURIComponent("" + body.programId) + "&";
  if (body.profileStatus === null)
      throw new Error("The parameter 'profileStatus' cannot be null.");
  else if (body.profileStatus !== undefined)
      url_ += "profileStatus=" + encodeURIComponent("" + body.profileStatus) + "&";
  if (body.page === null)
      throw new Error("The parameter 'page' cannot be null.");
  else if (body.page !== undefined)
      url_ += "page=" + encodeURIComponent("" + body.page) + "&";
  if (body.count === null)
      throw new Error("The parameter 'count' cannot be null.");
  else if (body.count !== undefined)
      url_ += "count=" + encodeURIComponent("" + body.count) + "&";
  if (body.familyPortalStatus !== undefined)
      url_ += "familyPortalStatus=" + encodeURIComponent("" + body.familyPortalStatus) + "&";
  if (body.SortDir !== undefined)
      url_ += "SortDir=" + encodeURIComponent("" + body.SortDir) + "&";
  if (body.SortField !== undefined)
      url_ += "SortField=" + encodeURIComponent("" + body.SortField) + "&";
  url_ = url_.replace(/[?&]$/, "");
  return httpClient().get(url_).then((response: any) => {
      return { data: response.status == 200 ? response.data : [], status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
      return { data: [], status: error.status, statusText: error.statusText };
  });

}

export async function getCsvInfoForGuardians(contactDataCsv: ContactDataCvsParams): Promise<{ data: string, fileName: string}> {
  try {
    const queryString = new URLSearchParams(
      Object.entries({ ...contactDataCsv })
        .filter(([, value]) => !!value)
        .map(([key, value]) => [key, value as string])
    ).toString();
    const response = await httpClientEnrollment().post(
      `${API_CONST.DOWNLOAD_CSV_GUARDIAN}?${queryString}`
    );
    const {
      data,
      headers: { ["content-disposition"]: fileName }
    } = response;
    return {
      data,
      fileName: fileName && fileName
        .split(';')[1]
        .split('filename=')[1]
        .replace(/['"]+/g, '') || 'Download.csv'
    };
  } catch (error) {
    return { data: '', fileName: '' };
  }
}
