import { render, staticRenderFns } from "./OffCanvas.vue?vue&type=template&id=62014517&scoped=true&"
import script from "./OffCanvas.vue?vue&type=script&lang=ts&"
export * from "./OffCanvas.vue?vue&type=script&lang=ts&"
import style0 from "./OffCanvas.vue?vue&type=style&index=0&id=62014517&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62014517",
  null
  
)

export default component.exports