import { RosterViewModelDto, ScholarDto, HeaderConfig } from "@/Model/model";
import { FamilyPortalStatus, GuardianListPayload } from "@/Model/shared/types";
import { ColumnConfig } from '@/commoncomponents/smartTableComponents/types';
import APP_CONST from "@/constants/AppConst";
import {
  getParticipants,
  guardianInfo
} from "@/services/enrollment/enrollmentService";
import store from "@/store";
import APP_UTILITIES from "@/utilities/commonFunctions";
import ParticipantTableHelper from '@/utilities/ParticipantTableHelper'
import { AxiosResponse } from "axios";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from "vuex-module-decorators";

import { getParticipantHeaderConfig } from '@/services/scholar/scholarService';

@Module({
  namespaced: true,
  name: "accountParticipants",
  store,
  dynamic: true
})
class accountParticipants extends VuexModule {
  manageData = {};
  responseStatus: number = 0;
  searchData = {};
  list: ScholarDto[] = [];
  count: number = 0;
  participantsPayload = APP_CONST.SCHOLAR_FAMILY_PAYLOAD;
  contactsPayload = {
    ...APP_CONST.SCHOLAR_FAMILY_PAYLOAD,
    familyPortalStatus: FamilyPortalStatus.All
  };
  public participantsSettingsObj: any = {};
  public participantsResizableArray: any[] = [];
  public participantsResizeObj: any = [];
  public participantsResizeData: any = [];
  public participantColumnConfigList: ColumnConfig[] = [];
  public contactsSettingsObj: any = {};
  public contactsResizableArray: any[] = [];
  public contactsResizeObj: any = [];
  public contactsResizeData: any = [];
  public authKey: string = "";

  public searchParticipant: Record<string, string> = {
    firstName: "",
    lastName: "",
    grade: "",
    guardians: "",
    programsAndSessions: ""
  };
  public searchContacts: Record<string, string> = {
    firstName: "",
    lastName: "",
    participant: "",
    phone: "",
    email: "",
    familyPortalStatus: ''
  };
  public allParticipantsSearchObj: any = [];
  public allParticipantsSortObj: any = [];
  public allContactsSearchObj: any = [];
  public allContactsSortObj: any = [];

  get participantsTabPayload() {
    return this.participantsPayload;
  }

  get guardianInfoPayload() {
    return this.contactsPayload;
  }

  get manageList() {
    return this.manageData;
  }

  get countOfParticipantsList() {
    return this.count;
  }

  get totalParticipantsList() {
    return this.list;
  }

  get participantsResizeArray() {
    return this.participantsResizeData;
  }
  get contactsResizeArray() {
    return this.contactsResizeData;
  }

  get searchParticipants() {
    return this.searchParticipant;
  }

  get allParticipantSearchObj() {
    return this.allParticipantsSearchObj;
  }

  get participantColumnConfig() {
    return this.participantColumnConfigList;
  }

  @Mutation
  updateManageData(manageData: any) {
    this.manageData = manageData;
  }

  @Action({ commit: "updateManageData" })
  async getManageList(payload: typeof APP_CONST.SCHOLAR_FAMILY_PAYLOAD) {
    return getParticipants(payload);
  }
  @Action({ commit: "updateManageData" })
  async getGuardianManageList(payload: GuardianListPayload) {
    return guardianInfo(payload);
  }

  @Mutation
  public updateList(data: AxiosResponse<RosterViewModelDto>) {
    this.list = data.data.scholars;
    this.responseStatus = data.status;
    this.count = data.data.totalScholarsCount;
  }

  @Action({ commit: "updateList" })
  public fetchList(payload: typeof APP_CONST.SCHOLAR_FAMILY_PAYLOAD) {
    return getParticipants(payload);
  }

  @Action({ commit: "updateList" })
  public fetchGuardianList(payload: GuardianListPayload) {
    return guardianInfo(payload);
  }

  @Action({ commit: 'mutateParticipantColumnConfig' })
  async setParticipantColumnConfig(accountId: string) {
    const { status, data } = await getParticipantHeaderConfig(accountId);
    const columnConfig: ColumnConfig[] = [];

    if (status == APP_CONST.RESPONSE_200) {
      if (data.scholar) {
        const participantColumns = ParticipantTableHelper.createSingleColumns(data.scholar, true, '');
        columnConfig.push(...participantColumns);
      }

      if (data.guardian) {
        const guardianColumn = ParticipantTableHelper.createGuardianColumnGroup(data.guardian, '');
        columnConfig.push(guardianColumn);
      }

      if (data.transportationInformation) {
        const authorizedPickupColumn = ParticipantTableHelper.createContactColumnGroup(
          data.transportationInformation,
          'Authorized Pickup',
          'authorizedPickup',
          ''
        );
        columnConfig.push(authorizedPickupColumn);
      }

      if (data.emergencyContact) {
        const emergencyContactColumn = ParticipantTableHelper.createContactColumnGroup(
          data.emergencyContact,
          'Emergency Contact Information',
          'emergencyContact',
          ''
        );
        columnConfig.push(emergencyContactColumn);
      }

      if (data.customFields) {
        const customFieldsColumns = ParticipantTableHelper.createSingleColumns(data.customFields, false, 'custom');
        columnConfig.push(...customFieldsColumns);
      }
    }
    return columnConfig;
  }

  @Mutation
  mutateParticipantColumnConfig(columnConfig: ColumnConfig[]) {
    this.participantColumnConfigList = [ ...ParticipantTableHelper.createDefaultParticipantColumns(), ...columnConfig ];
  }

  @Mutation
  mutateResizeData(obj: { column: any; maxWidth: string; minWidth: string }) {
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    if (!this.participantsResizeObj.hasOwnProperty([accountId])) {
      this.participantsResizableArray = [];
    } else if (this.participantsResizeObj.hasOwnProperty([accountId])) {
      this.participantsResizableArray = JSON.parse(
        JSON.stringify(this.participantsResizeObj[accountId])
      );
    }

    const keyIndex = this.participantsResizableArray.findIndex(
      (e: { id: any }) => e.id === obj.column.id
    );
    if (keyIndex > -1) {
      this.participantsResizableArray[keyIndex] = {
        id: obj.column.id,
        maxWidth: obj.maxWidth,
        minWidth: obj.minWidth
      };
    } else {
      this.participantsResizableArray.push({
        id: obj.column.id,
        maxWidth: obj.maxWidth,
        minWidth: obj.minWidth
      });
    }

    if (!Object.keys(this.participantsResizeObj).length) {
      this.participantsResizeObj = {
        [accountId]: JSON.parse(JSON.stringify(this.participantsResizableArray))
      };
    }
    if (this.participantsResizeObj.hasOwnProperty([accountId])) {
      this.participantsResizeObj[accountId] = JSON.parse(
        JSON.stringify(this.participantsResizableArray)
      );
    } else {
      const newObj = {
        [accountId]: JSON.parse(JSON.stringify(this.participantsResizableArray))
      };
      Object.assign(
        this.participantsResizeObj,
        JSON.parse(JSON.stringify(newObj))
      );
    }
    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-participantsResizeData`,
      this.participantsResizeObj
    );
  }

  @Mutation
  mutateResizeDataContacts(obj: {
    column: any;
    maxWidth: string;
    minWidth: string;
  }) {
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    if (!this.contactsResizeObj.hasOwnProperty([accountId])) {
      this.contactsResizableArray = [];
    } else if (this.contactsResizeObj.hasOwnProperty([accountId])) {
      this.contactsResizableArray = JSON.parse(
        JSON.stringify(this.contactsResizeObj[accountId])
      );
    }

    const keyIndex = this.contactsResizableArray.findIndex(
      (e: { id: any }) => e.id === obj.column.id
    );
    if (keyIndex > -1) {
      this.contactsResizableArray[keyIndex] = {
        id: obj.column.id,
        maxWidth: obj.maxWidth,
        minWidth: obj.minWidth
      };
    } else {
      this.contactsResizableArray.push({
        id: obj.column.id,
        maxWidth: obj.maxWidth,
        minWidth: obj.minWidth
      });
    }

    if (!Object.keys(this.contactsResizeObj).length) {
      this.contactsResizeObj = {
        [accountId]: JSON.parse(JSON.stringify(this.contactsResizableArray))
      };
    }
    if (this.contactsResizeObj.hasOwnProperty([accountId])) {
      this.contactsResizeObj[accountId] = JSON.parse(
        JSON.stringify(this.contactsResizableArray)
      );
    } else {
      const newObj = {
        [accountId]: JSON.parse(JSON.stringify(this.contactsResizableArray))
      };
      Object.assign(this.contactsResizeObj, JSON.parse(JSON.stringify(newObj)));
    }
    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-participantsResizeData`,
      this.contactsResizeObj
    );
  }

  @Mutation
  mutateSettingsData(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    let accountId: string | null = null;
    const userRoles = APP_UTILITIES.getCookie("highest_role");
    if (
      userRoles &&
      Number.parseInt(userRoles, 10) >= APP_CONST.ROLES.ACCOUNT_ADMIN
    ) {
      const role = APP_UTILITIES.getCookie("user_role");
      if (role) {
        const userData = JSON.parse(role);
        accountId = userData[0].accountId;
      }
    } else {
      accountId = APP_UTILITIES.getCookie("accountId");
    }
    if (!Object.keys(this.participantsSettingsObj).length && accountId) {
      this.participantsSettingsObj = {
        [accountId]: JSON.parse(JSON.stringify(data))
      };
    }
    if (accountId) {
      if (this.participantsSettingsObj.hasOwnProperty([accountId])) {
        this.participantsSettingsObj[accountId] = JSON.parse(
          JSON.stringify(data)
        );
      } else {
        const newObj = { [accountId]: JSON.parse(JSON.stringify(data)) };
        Object.assign(
          this.participantsSettingsObj,
          JSON.parse(JSON.stringify(newObj))
        );
      }
    }
    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-ParticipantSettingsData`,
      this.participantsSettingsObj
    );
  }

  @Mutation
  mutateContactsSettingsData(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    let accountId: string | null = null;
    const userRoles = APP_UTILITIES.getCookie("highest_role");
    if (
      userRoles &&
      Number.parseInt(userRoles, 10) >= APP_CONST.ROLES.ACCOUNT_ADMIN
    ) {
      const role = APP_UTILITIES.getCookie("user_role");
      if (role) {
        const userData = JSON.parse(role);
        accountId = userData[0].accountId;
      }
    } else {
      accountId = APP_UTILITIES.getCookie("accountId");
    }
    if (!Object.keys(this.contactsSettingsObj).length && accountId) {
      this.contactsSettingsObj = {
        [accountId]: JSON.parse(JSON.stringify(data))
      };
    }
    if (accountId) {
      if (this.contactsSettingsObj.hasOwnProperty([accountId])) {
        this.contactsSettingsObj[accountId] = JSON.parse(JSON.stringify(data));
      } else {
        const newObj = { [accountId]: JSON.parse(JSON.stringify(data)) };
        Object.assign(
          this.contactsSettingsObj,
          JSON.parse(JSON.stringify(newObj))
        );
      }
    }
    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-ContactsSettingsData`,
      this.contactsSettingsObj
    );
  }

  @Mutation
  mutateParticipantsPayload(val: any) {
    this.participantsPayload = val;

    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    this.participantsResizeData = [];
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    if (
      APP_UTILITIES.getFilteredColumns(`${this.authKey}-participantsResizeData`)
    ) {
      let participantsResizeObject: any = APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-participantsResizeData`
      );
      participantsResizeObject = JSON.parse(participantsResizeObject);
      this.participantsResizeObj = participantsResizeObject;
      if (participantsResizeObject.hasOwnProperty([accountId])) {
        this.participantsResizeData = participantsResizeObject[accountId];
      }
    }
    if (
      APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-AllParticipantsSearchObj`
      )
    ) {
      let allSearch: any = APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-AllParticipantsSearchObj`
      );
      allSearch = JSON.parse(allSearch);
      this.allParticipantsSearchObj = allSearch;

      if (allSearch.hasOwnProperty([accountId])) {
        this.searchParticipant = allSearch[accountId];
        const searchKeyResult = Object.keys(allSearch[accountId]).find(
          keyName =>
            allSearch[accountId][keyName] != "" &&
            allSearch[accountId][keyName] != null
        );
        this.participantsPayload["searchField"] = searchKeyResult
          ? searchKeyResult
          : "";
        this.participantsPayload["search"] =
          searchKeyResult &&
          allSearch[accountId] &&
          allSearch[accountId][searchKeyResult]
            ? allSearch[accountId][searchKeyResult]
            : "";
      }
    }
    if (
      APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-ParticipantsSortPayload`
      )
    ) {
      let payload: any = APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-ParticipantsSortPayload`
      );
      payload = JSON.parse(payload);
      this.allParticipantsSortObj = payload;
      if (payload.hasOwnProperty([accountId])) {
        this.participantsPayload["sortField"] = payload[accountId].sortField
          ? payload[accountId].sortField
          : "";
        this.participantsPayload["sortDir"] = payload[accountId].sortDir
          ? payload[accountId].sortDir
          : "";
      }
    }
  }

  @Mutation
  mutateContactsPayload(val: any) {
    this.contactsPayload = val;

    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    this.contactsResizeData = [];
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    if (
      APP_UTILITIES.getFilteredColumns(`${this.authKey}-contactsResizeData`)
    ) {
      let contactsResizeObject: any = APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-contactsResizeData`
      );
      contactsResizeObject = JSON.parse(contactsResizeObject);
      this.contactsResizeObj = contactsResizeObject;
      if (contactsResizeObject.hasOwnProperty([accountId])) {
        this.contactsResizeData = contactsResizeObject[accountId];
      }
    }
    if (
      APP_UTILITIES.getFilteredColumns(`${this.authKey}-AllContactsSearchObj`)
    ) {
      let allSearch: any = APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-AllContactsSearchObj`
      );
      allSearch = JSON.parse(allSearch);
      this.allContactsSearchObj = allSearch;

      if (allSearch.hasOwnProperty([accountId])) {
        this.searchContacts = allSearch[accountId];
        const searchKeyResult = Object.keys(allSearch[accountId]).find(
          keyName =>
            allSearch[accountId][keyName] != "" &&
            allSearch[accountId][keyName] != null
        );

        this.contactsPayload["searchField"] = searchKeyResult
          ? searchKeyResult
          : "";
        this.contactsPayload["search"] =
          searchKeyResult &&
          allSearch[accountId] &&
          allSearch[accountId][searchKeyResult]
            ? allSearch[accountId][searchKeyResult]
            : "";
      }
    }
    if (
      APP_UTILITIES.getFilteredColumns(`${this.authKey}-ContactsSortPayload`)
    ) {
      let payload: any = APP_UTILITIES.getFilteredColumns(
        `${this.authKey}-ContactsSortPayload`
      );
      payload = JSON.parse(payload);
      this.allContactsSortObj = payload;
      if (payload.hasOwnProperty([accountId])) {
        this.contactsPayload["sortField"] = payload[accountId].sortField
          ? payload[accountId].sortField
          : "";
        this.contactsPayload["sortDir"] = payload[accountId].sortDir
          ? payload[accountId].sortDir
          : "";
      }
    }
  }

  @Mutation
  mutateSortPayload(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    const accountId: any = APP_UTILITIES.getCookie("accountId");

    const payload = {
      ...this.allParticipantsSortObj,
      [accountId]: JSON.parse(JSON.stringify(data))
    };

    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-ParticipantsSortPayload`,
      payload
    );
  }
  @Mutation
  mutateSortContactsPayload(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    const accountId: any = APP_UTILITIES.getCookie("accountId");

    const payload = {
      ...this.allContactsSortObj,
      [accountId]: JSON.parse(JSON.stringify(data))
    };

    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-ContactsSortPayload`,
      payload
    );
  }

  @Mutation
  mutateCurrentObj(data: any) {
    this.searchParticipant = data;
  }
  @Mutation
  mutateCurrentContact(data: any) {
    this.searchContacts = data;
  }

  @Mutation
  mutateParticipantsSearchObj(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    this.searchParticipant = data;
    this.allParticipantsSearchObj = {
      ...this.allParticipantsSearchObj,
      [accountId]: JSON.parse(JSON.stringify(data))
    };

    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-AllParticipantsSearchObj`,
      this.allParticipantsSearchObj
    );
  }
  @Mutation
  mutateContactsSearchObj(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    this.searchParticipant = data;
    this.allContactsSearchObj = {
      ...this.allContactsSearchObj,
      [accountId]: JSON.parse(JSON.stringify(data))
    };

    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-AllContactsSearchObj`,
      this.allContactsSearchObj
    );
  }
  @Mutation
  mutateGuardianSearchObj(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : "";
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    this.searchContacts = data;
    this.allContactsSearchObj = {
      ...this.allContactsSearchObj,
      [accountId]: JSON.parse(JSON.stringify(data))
    };
    APP_UTILITIES.setFilteredColumns(
      `${this.authKey}-AllContactsSearchObj`,
      this.allContactsSearchObj
    );
  }
}
export default getModule(accountParticipants);
