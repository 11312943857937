import { Vue } from "vue-property-decorator";
import APP_UTILITIES from '@/utilities/commonFunctions';

import { ColumnConfig } from '@/commoncomponents/smartTableComponents/types';

import {
  GuardianDto,
  AuthorisedPickupDto,
  EmergencyContactDto,
  CustomFieldDto,
  ScholarMedicalDto,
  ScholarLearningDto,
  HeaderConfig
} from '@/Model/model';

/**
 * This file contains multiple functions related to processing/formatting of the
 * participant data. They are mainly used by the Participants.vue component
 * and the accountParticipants.ts store.
 *
 * If you find that any of these functions can be reused,
 * please move them to the commonFunctions.ts file.
 */
export default class ParticipantTableHelper extends Vue {

  static COLUMN_ACTIONS_TO_HIDE = new Set<string>([
    'allergies',
    'epiPen',
    'asthmaInhaler',
    'medication',
    'insuranceProvider',
    'iep',
    'llp',
    'specialEducation'
  ]);

  public static flatGuardianDetails(guardianDetails: GuardianDto[]) {
    return guardianDetails.reduce((acc, guardian, index) => {
      const position = (index + 1)

      return {
        ...acc,
        [`guardianFirstName${position}`]: guardian.firstName,
        [`guardianLastName${position}`]: guardian.lastName,
        [`guardianEmail${position}`]: guardian.email,
        [`guardianPhone${position}`]: guardian.phone ? `${APP_UTILITIES.formatNumber(guardian.phone)} ${guardian.phoneType || ''}` : '',
        [`guardianRelation${position}`]: guardian.relation,
        [`guardianHomeLanguage${position}`]: guardian.homeLanguage,
        [`guardianAddress${position}`]: guardian.address ? APP_UTILITIES.formatAddress(guardian) : '',
      }
    }, {});
  }

  public static flatContactDetails(contactDetails: Array<AuthorisedPickupDto | EmergencyContactDto>, prefix: string) {
    return contactDetails.reduce((acc, contact, index) => {
      const position = (index + 1)

      return {
        ...acc,
        [`${prefix}${position}firstName`]: contact.firstName,
        [`${prefix}${position}lastName`]: contact.lastName,
        [`${prefix}${position}phone`]: contact.phone ? `${APP_UTILITIES.formatNumber(contact.phone)} ${contact.phoneType || ''}` : '',
        [`${prefix}${position}relation`]: contact.relation,
      }
    }, {});
  }

  public static flatCustomFields(customFields: CustomFieldDto[], prefix: string) {
    return customFields.reduce((acc, customField) => {
      return {
        ...acc,
        [`${prefix}${customField.guid}`]: customField.response,
      }
    }, {});
  }

  public static flatMedicalDetails(medicalDetails: ScholarMedicalDto) {
    return medicalDetails ? {
      allergies: medicalDetails.allergies,
      epiPen: medicalDetails.epiPen,
      asthmaInhaler: medicalDetails.asthmaInhaler,
      medication: medicalDetails.medication,
      insuranceProvider: medicalDetails.insuranceProvider
    } : {};
  }

  public static flatLearningDetails(scholarLearning: ScholarLearningDto) {
    return scholarLearning ? {
      iep: scholarLearning.iep,
      llp: scholarLearning.llp,
      specialEducation: scholarLearning.specialEducation
    } : {};
  }

  public static createColumn(value: string, key: string, width: string, action: boolean, prefix: string): ColumnConfig {

    return {
      name: value,
      value: `${prefix}${key}`,
      filterKey: key,
      width,
      action: ParticipantTableHelper.shouldDisplayActionOptions(key) && action,
      isOpen: false,
      isSort: true,
      isSearch: true,
      isSearchList: true,
      display: false
    }
  }

  public static createSingleColumns(headerConfig: HeaderConfig[], action: boolean, prefix: string) {
    return headerConfig.map(({ value, key }) => ParticipantTableHelper.createColumn(value, key, '200px', action, prefix));
  }

  public static createGuardianColumnGroup(guardians: HeaderConfig[], prefix: string) {
    const guardianColumn = ParticipantTableHelper.createColumn('Detailed Guardian Information', 'guardianInformation', '200px', false, prefix);
    guardianColumn.groupedColumns = [];

    Array.from({ length: 2 }).forEach((_, index) => {
      const guardianNumber = index + 1;
      const guardianGroupedColumns = guardians.map(({
        value, key
      }) => {
        // Modifies the format of the guardians labels based on the guardian position,
        // instead of displaying 'Guardian Last Name', it will display as 'Guardian 1 Last Name'
        const guardianLabel = value.replace(' ', ` ${guardianNumber} `)
        return ParticipantTableHelper.createColumn(`${guardianLabel}`, `${key}${guardianNumber}`, '300px', false, prefix)
      });

      guardianColumn.groupedColumns!.push(...guardianGroupedColumns);
    })

    return guardianColumn;
  }

  public static createContactColumnGroup(contactHeaders: HeaderConfig[], headerName: string, headerKey: string, prefix: string) {
    const contactColumn = ParticipantTableHelper.createColumn(headerName, headerKey, '300px', false, prefix);
    contactColumn.groupedColumns = contactHeaders
      .map(({ value, key }) => ParticipantTableHelper.createColumn(value, key, '300px', false, prefix));

    return contactColumn;
  }

  public static createDefaultParticipantColumns(): ColumnConfig[] {
    return [
      {
        name: 'Grade',
        value: 'grade',
        action: true,
        isOpen: false,
        display: true,
        width: '136px',
        isSort: true,
        isSearch: true,
        isSearchList: true,
        filterKey: 'grade',
      },
      {
        name: 'Guardians',
        value: 'guardians',
        action: false,
        isOpen: false,
        display: true,
        width: '300px',
        isSort: false,
        isSearch: true,
        isSearchList: false,
        filterKey: 'guardianDetails',
      },
      {
        name: 'Programs & Sessions',
        value: 'programsAndSessions',
        action: false,
        isOpen: false,
        display: true,
        width: '350px',
        isSort: true,
        isSearch: true,
        isSearchList: false,
        filterKey: 'programsAndSessions',
      }
    ]
  }

  private static shouldDisplayActionOptions(columnKey: string): boolean {
    // Participant endpoint doesn't support medical information to be sorted
    // or filtered, so actions for these columns need to be disabled for now.
    return !ParticipantTableHelper.COLUMN_ACTIONS_TO_HIDE.has(columnKey);
  }

}
